import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { catchError, EMPTY, map, Observable, of, take, tap } from 'rxjs';
import { IPrmcBlogPost } from 'src/app/interfaces/contentful';
import { ContentfulService } from 'src/app/services/contentful.service';
import { MetaTagService } from 'src/app/services/meta-tag.service';
import { TitleService } from 'src/app/services/title.service';

@Injectable({
  providedIn: 'root'
})
export class BlogPostResolver implements Resolve<IPrmcBlogPost | null> {

  constructor(private router: Router, private cms: ContentfulService, private metaTagService: MetaTagService, private titleService: TitleService) {

  }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IPrmcBlogPost | null> {
    const slug = route.paramMap.get("page-slug");
    const pageType = route.paramMap.get("page-type");

    if (!slug || !pageType) {
      return of(null);
    }

    //try to get the content for the page slug
    return this.cms.getContent(pageType!, { "fields.slug": slug }).pipe(map(res => {
      if (!res) {
        return null;
      } else {
        const blogContent = res[0] as IPrmcBlogPost;

        //Adding meta tag
        if (blogContent?.fields?.metaTag) {
          this.metaTagService.updateMetaTag(blogContent?.fields?.metaTag);
        }

        //Updating title
        if(blogContent?.fields?.heading){
          this.titleService.setTitle(blogContent.fields.heading);
        }

        return blogContent as IPrmcBlogPost;
      }
    }, catchError((error, caught) => {
      console.log("Error loading blog post: ", error);
      return of(null);
    })));

  }

}
