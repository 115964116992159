<footer *ngIf="footerContent">

    <div class="footerContent">
        <div class="logoContainer">
            <img src="assets/images/logo_transparent.png" alt="Primary Connect logo" class="logo">
        </div>

        <div class="footerLinksContainer">
            <div class="copyrightContainer">
                <p>{{footerContent.fields.legalText}}</p>

                <ul>
                    <li *ngFor="let link of footerContent.fields.legalLinks">
                        <a [appHandleCMSLink]="link">
                            {{link.fields.displayText}}
                        </a>
                    </li>
                </ul>
            </div>

            <ul class="socialLinks">
                <li *ngFor="let link of footerContent.fields.socialLinks">
                    <a [href]="link.fields.url">
                        <fa-icon *ngIf="link.fields.icon"
                            [icon]="[link.fields.icon.fields.iconFamily, link.fields.icon.fields.iconName]"
                            aria-hidden="true"></fa-icon>
                        <div>{{link.fields.displayText}}</div>
                    </a>
                </li>
            </ul>
        </div>
        <div class="aocContainer">
            <div class="aocLogoContainer">
                <div class=" aocIcon">
                    <img [src]="footerContent.fields.aocImage?.fields?.file?.url"
                        alt="Acknowledgement of Country Digital Recommendations logo">
                </div>
            </div>
            <div class="aocContent">

                <p>{{footerContent.fields.aocText}}</p>

                <a *ngIf="footerContent.fields.aocLink" [appHandleCMSLink]="footerContent.fields.aocLink">
                    <div>{{footerContent.fields.aocLink.fields.displayText}}</div>
                </a>


            </div>


        </div>

    </div>

</footer>
