import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, take, takeUntil } from 'rxjs';
import { IPrmcContactUs } from 'src/app/interfaces/contentful';
import { ContentfulService } from 'src/app/services/contentful.service';
import { MetaTagService } from 'src/app/services/meta-tag.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  contactUsContent?: IPrmcContactUs;
  private componentDestroyed = new Subject<void>();
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cms: ContentfulService,
    private metaTagService: MetaTagService,
    private titleService: TitleService,
  ) {
    this.titleService.setTitle("Contact Us");
    this.watchForRouteChange();
  }
  /**
   * If the user clicks a different blog link, the route will change but since the target component is the same, the page won't reload on its own.
   */
  watchForRouteChange() {
    this.route.paramMap
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(params => {
        this.getContent();
      });
  }
  /**
   * Get content for the currently requested blog post
   */
  async getContent() {

    //try to get content for that slug
    this.cms.getContent("prmcContactUs").pipe(take(1)).subscribe(res => {
      this.contactUsContent = res[0] as IPrmcContactUs;

      //if we couldn't find a matching post, send them to the 404 page
      if (!this.contactUsContent) {
        this.router.navigateByUrl('/page-not-found')
      }
      //Adding meta tag
      if (this.contactUsContent?.fields?.metaTag)
        this.metaTagService.updateMetaTag(this.contactUsContent?.fields?.metaTag);
    });

  }
  ngOnInit(): void {
  }
  
  ngOnDestroy() {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }

}
