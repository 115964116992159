import { Environment } from "./environment.interface";

export const environment: Environment = {
  baseUrl: 'https://uat1-primaryconnect.woolworths.com.au/api/',
  production: true,
  contentful: {
    space: '3h8j9vctv5nr',
    contentfulBaseURL: 'https://cdn.contentful.com',
    contentfulProxyBaseURL: 'https://uat1-primaryconnect.woolworths.com.au/api/',
    environment: 'master',
    accessToken: 'zGgtMPv-gKv6DjaegRdNjYMpJZA4dGhXoTGDW_gqXT0'
  },
  urlToShowPreviewOptions: 'uat1-primaryconnect.woolworths.com.au'
};