<div class="heroImage" *ngIf="contactUsContent?.fields?.heroImage?.fields?.file?.url"
  [style.background-image]="'url(' + contactUsContent?.fields?.heroImage?.fields?.file?.url + ')'">

  <div  *ngIf="contactUsContent?.fields?.heading ||contactUsContent?.fields?.subheading" class="heroTitle">
    <h2>{{contactUsContent?.fields?.heading}}</h2>
    <p>{{contactUsContent?.fields?.subheading}}</p>
  </div>
</div>


<section class="contactUsWrapper">
  <div class="grid">
    <div class="col">
      <img class="mainLogoImage" *ngIf="contactUsContent?.fields?.mainLogo?.fields?.file?.url"
        [src]="contactUsContent?.fields?.mainLogo?.fields?.file?.url" alt="Primary Connect contact us logo">

    </div>
    <div class="col">
      <app-address-container *ngIf="contactUsContent?.fields?.address?.fields"
        [forAllEnquiriesAndFeedback]="contactUsContent?.fields?.address?.fields?.forAllEnquiriesAndFeedback"
        [address1]="contactUsContent?.fields?.address?.fields?.address1 "
        [phone]="contactUsContent?.fields?.address?.fields?.phone "
        [phoneLink]="contactUsContent?.fields?.address?.fields?.phoneLink "
        [email]="contactUsContent?.fields?.address?.fields?.email "
        [emailLink]="contactUsContent?.fields?.address?.fields?.emailLink "
        [poBox]="contactUsContent?.fields?.address?.fields?.poBox "></app-address-container>

    </div>
  </div>
  <div class=" grid">
    <div class="col">
      <img class="logoNzImage" *ngIf="contactUsContent?.fields?.logoNz?.fields?.file?.url"
        [src]="contactUsContent?.fields?.logoNz?.fields?.file?.url" alt="Primary Connect contact us logo">

    </div>
    <div class="col">
      <app-address-container *ngIf="contactUsContent?.fields?.nzContactAddress?.fields"
        [forAllEnquiriesAndFeedback]="contactUsContent?.fields?.nzContactAddress?.fields?.forAllEnquiriesAndFeedback"
        [address1]="contactUsContent?.fields?.nzContactAddress?.fields?.address1 "
        [phone]="contactUsContent?.fields?.nzContactAddress?.fields?.phone "
        [email]="contactUsContent?.fields?.nzContactAddress?.fields?.email "
        [emailLink]="contactUsContent?.fields?.nzContactAddress?.fields?.emailLink "
        [phoneLink]="contactUsContent?.fields?.nzContactAddress?.fields?.phoneLink ">
      </app-address-container>
    </div>
  </div>
</section>
