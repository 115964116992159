import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-address-container',
  templateUrl: './address-container.component.html',
  styleUrls: ['./address-container.component.scss']
})
export class AddressContainerComponent implements OnInit {
  @Input() forAllEnquiriesAndFeedback?: string;

  /** Address1 */
  @Input() address1?: string;

  /** Phone */
  @Input() phone?: number;

  /** Email */
  @Input() email?: string;

  /** PO Box */
  @Input() poBox?: string;

  /** email link */
  @Input() emailLink?: string;
  /** phone Link */
  @Input() phoneLink?: string;

  constructor() { }

  ngOnInit(): void {
  }

}
