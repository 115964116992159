<section class="addressContainer">

  <h5 *ngIf="forAllEnquiriesAndFeedback">{{forAllEnquiriesAndFeedback}}</h5>
  <div *ngIf="address1" class="linkListing">
      <img class="linkIcon" alt="Postal Address" src="assets/icons/Address_Green.png">
      <div class="linkDescription">
          <p>{{address1}}</p>
      </div>
  </div>
  <div *ngIf="phone" class="linkListing">
      <img class="linkIcon" alt="Phone Number" src="assets/icons/Phone_Green.png">
      <div class="linkDescription">
          <a [href]="phoneLink" target="_self">
              <p>{{phone}}</p>
          </a>
      </div>
  </div>
  <div *ngIf="email" class="linkListing">
      <img class="linkIcon" alt="Email" src="assets/icons/Email_Green.png">
      <div class="linkDescription">
          <a [href]="emailLink">{{email}}</a>
      </div>
  </div>
  <div *ngIf="poBox" class="linkListing">
      <img class="linkIcon" alt="POst box"  src="assets/icons/PO_Box_Green.png">
      <div class="linkDescription">
          <p>{{poBox}}</p>
      </div>
  </div>
</section>
