import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ResolveStart, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  constructor(private titleService: Title, private router: Router) {
    this.watchAndResetTitleOnRouteChange();

  }

  /**
   * When the route is changed, reset the page title to the default one.
   * We expect each page to set it's own title, but in the case that a page doesn't set a title we won't
   * be stuck with the old page title from the previous page.
   */
  watchAndResetTitleOnRouteChange() {
    this.router.events.subscribe((event) => {
      if (event instanceof ResolveStart) {
        this.titleService.setTitle("Primary Connect | Moving products for our customers");
      }
    });
  }

  /**
   * Set's the page title.
   * @param title title without the "Primary Connect |" prefix
   */
  setTitle(title: string) {
    this.titleService.setTitle("Primary Connect | " + this.toTitleCase(title));
  }


  /**
   * Converts a string to title case.
   * @param string string to conver
   * @returns string as title case
   */
  toTitleCase(string: string) {
    return string.replace(
      /\w\S*/g,
      (txt) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }
}
